import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Dimmer, Icon, Loader, Modal, Image, Popup } from 'semantic-ui-react'
import '../../pages/calendar/style.less';
import { getServiceInstanceImage, getStandaloneSignageImage } from '../../services/ImageUploader';
import { changeNormalActivityAttendingStatus, changeServiceActivityAttendingStatus, updateServiceActivityAttendance } from '../../services/Calendar';
import sendToast from '../../../utils/Toast';
import { fetchByDocIds } from '../../services/Documents';
import { Document } from '../../types/Document';
import { fetchSvcInstanceAttendee } from '../../../services/activityAttendees';

type IconColor = "green" | "blue" | "red" | "orange" | "yellow" | "olive" | "teal" | "violet" | "purple" | "pink" | "brown" | "grey" | "black" | undefined;

const SelectedCalendarEventModal = ({ closeSelectedEventModal, openSelectedEventModal, selectedEventInfo, refreshCalendarEvents, setRefreshCalendarEvents }) => {
    const [imageUrl, setImageUrl] = useState("");
    const [isEventLoading, setIsEventLoading] = useState<boolean>(true)
    const [colorOfIcon, setColorOfIcon] = useState<IconColor>("green")
    const [attendingStatus, setAttendingStatus] = useState<string>("Attending")
    const [popUpContent, setPopUpContent] = useState<string>("")
    const isMounted = useRef(false);
    const [attachedDoc, setAttachedDoc] = useState<Document | null>(null);  

    useEffect(() => {
        isMounted.current = true;
        const fetchImage = async () => {
            try {
                setIsEventLoading(true);
                let image: string = "";
                setImageUrl("");
                if (selectedEventInfo.typeOfRequest === "service" && selectedEventInfo.image) {
                    image = await getServiceInstanceImage({
                        facilityId: selectedEventInfo.Facility,
                        svcInstanceId: selectedEventInfo.svcInstanceId,
                        fileName: selectedEventInfo.image,
                    });
                } else if (selectedEventInfo.typeOfRequest === "normal" && selectedEventInfo.contentId) {
                    image = await getStandaloneSignageImage({
                        facilityId: selectedEventInfo.Facility,
                        contentType: "activities",
                        contentId: selectedEventInfo.contentId,
                    });
                } else {
                    image = selectedEventInfo.image || "";
                }
                if (isMounted.current) {
                    setImageUrl(image);
                }
            } catch (error) {
                console.log(error);
                sendToast("error", "Error fetching image")
            } finally {
                if (isMounted.current) {
                    setIsEventLoading(false);
                }
            }
        };

        const fetchByDocs = async () => {
            try {
                setIsEventLoading(true);
                const docIds = [selectedEventInfo.docId];
                if (docIds && docIds.length > 0) {
                    const { Result: doc } = await fetchByDocIds(selectedEventInfo.Facility, docIds);
                    setAttachedDoc(doc[0]); // currently only one document is attached to a service 
                }
            } catch (error) {
                sendToast("error", `Error fetching attached document`);
            } finally {
                if (isMounted.current) {
                    setIsEventLoading(false);
                }
            }
        }

        const getEventAttendingStatus = () => {
            const { attendingStatus, showAttendance } = selectedEventInfo;
            const status = attendingStatus.status
                ? attendingStatus.status.toLowerCase()
                : attendingStatus.toLowerCase();
            switch (status) {
                case 'notattending':
                    setColorOfIcon("blue");
                    setAttendingStatus("Not Attending");
                    if (showAttendance ) {
                        setPopUpContent("Register for the event");
                    } else {
                        setPopUpContent("Attendance is not allowed for the event");
                    }
                    break;
                case 'intend_attend':
                    setColorOfIcon("green");
                    setPopUpContent("You are registered for the event, (Click to decline)");
                    setAttendingStatus("Attending");
                    break;
                case 'attended':
                    setColorOfIcon("green");
                    setAttendingStatus("Attended");
                    break;
                case 'waiting':
                    setColorOfIcon("orange");
                    setPopUpContent("You are in waiting list for the event, (Click to decline)");
                    setAttendingStatus("Waiting List");
                    break;
                default:
                    setColorOfIcon("red");
                    setAttendingStatus("Declined");
                    break;
            }
        }
        if (selectedEventInfo) {
            fetchImage();
        }
        if (selectedEventInfo && selectedEventInfo.attendingStatus) {
            getEventAttendingStatus();
        }
        if (selectedEventInfo && selectedEventInfo.docId) {
            fetchByDocs()
        }
        return () => {
            isMounted.current = false;
        };
    }, [selectedEventInfo]);

    let isDisabled = attendingStatus === "Attended" || attendingStatus === "Declined" ;

    const handleChangeAttendingStatus = async () => {
        if (attendingStatus === "Not Attending" && selectedEventInfo.showAttendance) {
            const payload = {
                Facility: selectedEventInfo.Facility,
                status: "intend_attend", // change the status to intend_attend from notattending so that resident can get registered for the event
                registrantId: selectedEventInfo.registrantId,
                registrant: selectedEventInfo.registrant,
            };
            try {
                setIsEventLoading(true);
                let res;
                if (selectedEventInfo.typeOfRequest === "service") {
                    payload["svcInstanceId"] = selectedEventInfo.attendingId;
                    payload["svcType"] = "activity";
                    payload["isLockRegistrationCheckRequired"] = true
                    res = await changeServiceActivityAttendingStatus({ facilityId: selectedEventInfo.Facility, payload });
                } else if (selectedEventInfo.typeOfRequest === "normal") {
                    payload["activityId"] = selectedEventInfo.attendingId;
                    res = await changeNormalActivityAttendingStatus({ facilityId: selectedEventInfo.Facility, payload });
                }
                if (res) {
                    setAttendingStatus("Attending");
                    setColorOfIcon("green");
                    sendToast("success", "Succesfully Registered for the event");
                    closeSelectedEventModal();
                    setRefreshCalendarEvents(!refreshCalendarEvents); // Trigger the refresh of calendar events
                }
            } catch (error) {
                console.log(error);
                sendToast("error", error instanceof Error ? error.message : "Error changing attending status");
            } finally {
                setIsEventLoading(false);
            }
        } else if(selectedEventInfo.showAttendance && (attendingStatus === "Attending" || attendingStatus === "Waiting List")) {
            const payload = {
                Facility: selectedEventInfo.Facility,
                status: "removed",
            };
            let attendeeData: any = {};
            if (selectedEventInfo) {
                console.log("selectedEventInfo", selectedEventInfo);
                const { registrantId, svcInstanceId, Facility } = selectedEventInfo;
                if (svcInstanceId) {
                    const data = await fetchSvcInstanceAttendee(Facility, svcInstanceId, registrantId);
                    attendeeData = data.Result && data.Result[0];
                }
            }
            try {
                setIsEventLoading(true);
                let res;                
                if (selectedEventInfo.typeOfRequest === "service" && attendeeData) {
                    payload["svcInstanceId"] = attendeeData.svcInstanceId;
                    payload["svcType"] = "activity";
                    payload["attendeeObjId"] = String(attendeeData._id);
                    payload["declineReason"] = "Not Interested";
                    res = await updateServiceActivityAttendance({ facilityId: selectedEventInfo.Facility, payload });
                } 
                if (res) {
                    setAttendingStatus("Not Attending");
                    setColorOfIcon("blue");
                    sendToast("success", "Successfully Unregistered for the event");
                    closeSelectedEventModal();
                    setRefreshCalendarEvents(!refreshCalendarEvents); // Trigger the refresh of calendar events
                }
            } catch (error) {
                console.log(error);
                sendToast("error", error instanceof Error ? error.message : "Error changing attending status");
            } finally {
                setIsEventLoading(false);
            }
        }
    };

    return (
        <Modal open={openSelectedEventModal} onClose={closeSelectedEventModal} size="small" >
            <Dimmer active={isEventLoading} inverted>
                <Loader />
            </Dimmer>
            <Modal.Header style={{ textAlign: "center" }}>Calendar Event details</Modal.Header>
            <Modal.Content image >
                <Modal.Description style={{ margin: "0 80px" }}>
                    {(selectedEventInfo.image || selectedEventInfo.contentId) && imageUrl ?
                        <Image src={imageUrl} size='small' wrapped onError={() => setImageUrl("")}/> :
                        <Icon name='images outline' style={{ fontSize: "80px" }} />
                    }
                </Modal.Description>
                <Modal.Description>
                    <Container>
                        <p className='eventTitle'>{selectedEventInfo.title}</p>
                        <p className='eventTimeLocation'>
                            {selectedEventInfo.startTime} - {selectedEventInfo.location}
                        </p>

                        {selectedEventInfo.description && (
                            <>
                                <label className='eventLabel'>Description:</label>
                                <p className="eventDescription">{selectedEventInfo.description}</p>
                            </>
                        )}

                        {selectedEventInfo.longDescription && (
                            <>
                                <label className='eventLabel'>Details:</label>
                                <p className={`eventLongDescription ${selectedEventInfo.longDescription.length > 200 ? 'scrollable' : ''}`}>
                                    {selectedEventInfo.longDescription}
                                </p>
                            </>
                        )}

                        {attachedDoc && <>
                            <p className='singleEventDesc' style={{ fontWeight: 'bold' }}>Attached Document: <a href={attachedDoc.attachedFiles && attachedDoc.attachedFiles[0].attachedLink} target="_blank" rel="noopener noreferrer"><span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{attachedDoc.Name}</span></a></p>
                        </>} 
                        {
                            selectedEventInfo.typeOfRequest !== "resident" && (
                                <div>
                                    <p><span style={{ fontSize: "20px" }}>{attendingStatus}</span>
                                        {
                                            attendingStatus === "Declined" ? <i style={{ color: colorOfIcon }} className="fa-solid fa-circle-xmark crossIcon"></i>
                                                : attendingStatus === "Waiting List" ? <Popup
                                                    content={popUpContent}
                                                    trigger={
                                                        <Icon name='wait' size='big' color={colorOfIcon} onClick={() => handleChangeAttendingStatus()} />
                                                    }
                                                    disabled={isDisabled}
                                                />
                                                    : <Popup
                                                        content={popUpContent}
                                                        trigger={
                                                            <Icon name='check circle' style={{ cursor: attendingStatus === "Not Attending" ? "pointer" : "" }} size='big' color={colorOfIcon} onClick={() => handleChangeAttendingStatus()} />
                                                        }
                                                        disabled={isDisabled}
                                                    />
                                        }
                                    </p>
                                </div>
                            )
                        }
                    </Container>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={closeSelectedEventModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SelectedCalendarEventModal;
